import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const MottoPage = () => (
	<Layout
		meta={{
			title: "Continuous Improvement",
			description:
				"Accento Digital's topic is Continuous Improvement - why and what does it mean?",
			keywords: "continuous improvement",
			path: "/continuous-improvement",
		}}
		wide
	>
		<Snippet id="continuous-improvement" />
	</Layout>
)

export default MottoPage
